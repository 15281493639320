import React from "react";
import PropTypes from "prop-types";

const ArrRight = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="23.5" stroke="white" />
    <path
      d="M11.2929 24.7071C10.9024 24.3166 10.9024 23.6834 11.2929 23.2929L17.6569 16.9289C18.0474 16.5384 18.6805 16.5384 19.0711 16.9289C19.4616 17.3195 19.4616 17.9526 19.0711 18.3431L13.4142 24L19.0711 29.6569C19.4616 30.0474 19.4616 30.6805 19.0711 31.0711C18.6805 31.4616 18.0474 31.4616 17.6569 31.0711L11.2929 24.7071ZM36 25H12V23H36V25Z"
      fill="white"
    />
  </svg>
);

ArrRight.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
ArrRight.defaultProps = {
  onClick: null,
  className: "",
};

export default ArrRight;
