import React, { useState } from "react";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
import VideoThumbnail from "../ImageQuerys/PosterImages";
import playIcon from "../../images/svg/play.svg";
import logo from "../../images/svg/spoton-circle-white.svg";

const VideoSlide = (props) => {
  const { posterWrapperClass, posterImg, title, videoId } = props;
  const [playing, setPlaying] = useState(false);
  const startPlaying = () => {
    setPlaying(true);
  };
  const endPlaying = () => {
    setPlaying(false);
  };
  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
  return (
    <div
      className="video-slider-player h-[320px] md:h-[420px] rounded-lg z-10"
      style={{ borderRadius: "24px" }}
    >
      {!playing ? (
        <div
          className="video-slider-poster max-h-[320px] md:max-h-[420px] overflow-hidden z-10 rounded-lg"
          style={{ borderRadius: "24px" }}
        >
          <VideoThumbnail
            imageName={posterImg}
            objectFit="scale-down"
            style={{ borderRadius: "24px" }}
            className={`video-slider-poster-img z-[-9] max-h-[400px] ${posterWrapperClass}`}
          />
          <img
            src={logo}
            className="absolute left-5 md:left-10 top-5 md:top-10 z-10 w-[40px]"
            alt=""
          />
          <h2 className="video-slider-title z-10">{title}</h2>
          <button
            className="video-slider-btn z-10"
            type="button"
            onClick={startPlaying}
          >
            <img
              src={playIcon}
              alt="chevron icon"
              className="absolute top-[50%] left-[50%] translate-x-[-25%] translate-y-[-50%]"
            />
          </button>
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <YouTube
            videoId={videoId}
            onEnd={endPlaying}
            onReady={onPlayerReady}
            className="mt-4 h-[300px] md:h-[330px] xl:h-[400px] rounded-[32px]"
            opts={{
              playerVars: {
                autoplay: 1,
              },
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )}
    </div>
  );
};

VideoSlide.propTypes = {
  title: PropTypes.string,
  videoId: PropTypes.string,
  posterImg: PropTypes.string,
  posterWrapperClass: PropTypes.string,
};

VideoSlide.defaultProps = {
  title: "",
  videoId: "",
  posterImg: "",
  posterWrapperClass: "",
};

export default VideoSlide;
