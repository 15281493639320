import React, { useState } from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import ogImage from "../../images/global_assets/og-image.png";
import NewMediaStyles from "./new-media-styles";
import DownloadSection from "../../components/MediaComps/DownloadSection";
import chevron from "../../images/svg/chevron_left.svg";
import { mediaTypes, videoSlider } from "../../data/main-pages/events-page";
import VideoSlider from "../../components/MediaComps/VideoSlider";
import Image from "../../components/ImageQuerys/HeroImages";
import Articles from "../../components-v2/Articles";
import ProductsWithLinks from "../../components-v2/Sections/ProductsWithLinks";
import CtaSecondary from "../../components/Buttons/secondary-cta";

const NewMedia = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Media"
        description="Find out the lastest news about SpotOn and contact us for press inqueries"
        image={`https://spoton.com/${ogImage}`}
      />
      <section className="new-media z-0">
        <h1 className="new-media-title z-20">
          Newsroom<span className="blue_dot">.</span>
        </h1>
        <p className="new-media-p text-center">
          For media inquiries or more information, please contact us.
        </p>
        <a
          className="bg-primary table mx-auto px-6 py-3 rounded-full text-[16px] text-white special-cursor z-20 hover:text-white hover:bg-opacity-70 transition-all duration-200"
          href="mailto:press@spoton.com"
        >
          press@spoton.com
          <img
            src={chevron}
            alt="chevron icon"
            style={{ margin: "0px 5px 2px 10px" }}
          />
        </a>
        <Image
          className="absolute force-abs right-0 top-[-75px] lg:top-[-50px] w-[125px] lg:w-[175px]"
          imageName="custom-circle.png"
        />
        <Image
          className="new-media-shadow z-[-10]"
          imageName="fake-shadow.png"
        />
      </section>
      <DownloadSection />
      <section className="mx-[0px] lg:mx-[100px] pt-[45px]">
        <h1 className="new-media-title mx-2 md:mx-0" style={{ marginTop: 32 }}>
          The latest news<span className="blue_dot">.</span>
        </h1>
        <p className="text-center text-black-200 text-[20px] mb-[50px] mx-3 md:mx-0">
          SpotOn news and insights.
        </p>
        <Articles
          blogFilters={{
            limit: 6,
            include: "tags,authors",
            filter: "tag:press-releases",
          }}
          cornerStone={false}
          hideSnippet
          hideLink
          dateFormat="MMMM DD"
          offsets={[1, 4]}
          imageHeight="h-[293px]"
          style={{ margin: 0, paddingTop: 32, paddingBottom: 32 }}
        />
        <div className="table mx-auto mt-[32px] md:my-[80px]">
          <CtaSecondary
            ctaTitle="View Archive"
            target="https://spoton.com/blog/tag/press-releases/"
          />
        </div>
      </section>
      <section className="mx-[0px] lg:mx-[100px] pt-[45px]">
        <h1 className="new-media-title mx-3 md:mx-0" style={{ marginTop: 32 }}>
          Featured in<span className="blue_dot">.</span>
        </h1>
        <p className="text-center text-black-200 text-[20px] mb-[50px] text-[#596779]">
          Read about recent SpotOn features and awards.
        </p>
        <Articles
          blogFilters={{
            limit: 3,
            include: "tags,authors",
            filter: "",
          }}
          cornerStone={false}
          hideSnippet
          hideLink
          dateFormat="MMMM DD"
          imageHeight="h-[293px]"
          style={{ margin: 0, paddingTop: 32, paddingBottom: 32 }}
        />
      </section>
      <section id="media-links">
        <ProductsWithLinks className="mt-20 lg:mt-12 mb-32" {...mediaTypes} />
      </section>
      <section id="videos">
        <VideoSlider customArr videoElements={videoSlider} />
      </section>
      <NewMediaStyles />
    </Layout>
  );
};

export default NewMedia;
