// eslint-disable-next-line import/prefer-default-export
export const visualCards = [
  {
    cardTitle: "SpotOn\n Restaurant",
    cardImage: "restaurant-slide.png",
    cardLink: "/restaurant-pos",
  },
  {
    cardTitle: "SpotOn\n Retail & Services",
    cardImage: "retail-slide.png",
    cardLink: "/restaurant",
  },
  {
    cardTitle: "SpotOn\n Enterprise",
    cardImage: "enterprise-slide.png",
    cardLink: "/enterprise",
  },
];

export const videoSlider = [
  {
    title: "SpotOn: Restaurant Tech with 5-Star Service",
    videoId: "TVVJ26sjlwE",
    posterImg: "so-retail.png",
  },
  {
    title: "SpotOn Retail: Sell in-store, online, and on the go",
    videoId: "hLlIUkFESiw",
    posterImg: "so-rest.png",
  },
  {
    title:
      "Fire on All Cylinders with SpotOn: POS & More, Built for Automotive",
    videoId: "tv8lFtpM3Hs",
    posterImg: "so-auto.png",
  },
  {
    title: "SpotOn: Your Business Is Our Business",
    videoId: "8QVvWcGplAw",
    posterImg: "so-manifesto.png",
  },
  {
    title: "SpotOn & Derek Griffith: The Road to Success",
    videoId: "4pIhYB_LvJM",
    posterImg: "so-derekk.png",
  },
  {
    title: "SpotOn Sidekick: Portable POS for food trucks and more",
    videoId: "CKYuJeylAWM",
    posterImg: "so-sidekick.png",
  },
];

// eslint-disable-next-line import/prefer-default-export
export const mediaTypes = {
  title: "Who we serve",
  symbol: ".",
  subtext:
    "SpotOn builds integrated point-of-sale and business management solutions built to work the way you work.",
  products: [
    {
      title: "SpotOn \n Restaurant",
      linkTo: "/restaurant-pos/",
      imageName: "new-media-rest.png",
    },
    {
      title: "SpotOn \n Retail & Services",
      linkTo: "/retail/",
      imageName: "new-media-retail.png",
    },
    {
      title: "SpotOn \n Enterprise",
      linkTo: "/enterprise/",
      imageName: "new-media-enter.png",
    },
  ],
  // cards: [
  //   {
  //     bg: "restaurants.png",
  //     cardTitle: "SpotOn Restaurant",
  //     cardContent: "",
  //     linkTo: "/restaurant-pos",
  //   },
  //   {
  //     bg: "retail.png",
  //     cardTitle: "SpotOn Retail & Services",
  //     cardContent: "",
  //     linkTo: "/products/terminal",
  //   },
  //   {
  //     bg: "enterprise.png",
  //     cardTitle: "SpotOn Enterprise",
  //     cardContent: "",
  //     linkTo: "/enterprise",
  //   },
  // ],
};

export const mediaArticles = {
  title: "",
  bgText: "",
};
