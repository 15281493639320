import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import chevron from "../../images/svg/chevron_left.svg";
import logo from "../../images/svg/SpotOnLogo.svg";
import logoCir from "../../images/svg/spoton-circle-dark.svg";
import CtaSecondary from "../Buttons/secondary-cta";

import Image from "../ImageQuerys/RandomImages";
import HeadingBuilder from "../../components-v2/Base/HeadingBuilder";

const headshots = [
  {
    title: "Executive Headshots",
    images: [
      { name: "Zach Hyman", image: "Zach-Hyman.png" },
      { name: "RJ Horsley", image: "RJ-Horsley.jpg" },
      { name: "Michelle Zmugg", image: "Michelle-Zmugg.jpg" },
      { name: "Matt Hyman", image: "Matt-Hyman.png" },
      { name: "Mark Walz", image: "Mark-Walz.jpg" },
      { name: "Ken Welter", image: "Ken-Welter.jpg" },
      { name: "Doron Friedman", image: "Doron-Friedman.jpg" },
    ],
  },
];

const products = [
  {
    title: "Products in Situations",
    images: [
      { name: "Omnichannel", image: "_SpotOn Omnichannel-8.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-15.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-22.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-29.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-55.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-71.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-81.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-148.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-174.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-186.jpg" },
      { name: "Omnichannel", image: "_SpotOn Omnichannel-211.jpg" },
      { name: "In real life", image: "4U7A1909.jpg" },
      { name: "In real life", image: "4U7A7427.jpg" },
      { name: "In real life", image: "4U7A7544.jpg" },
      { name: "In real life", image: "4U7A7713.jpg" },
      { name: "In real life", image: "4U7A7926.jpg" },
      { name: "In real life", image: "4U7A9166.jpg" },
      { name: "In real life", image: "4U7A9313.jpg" },
      { name: "In real life", image: "4U7A9476.jpg" },
      { name: "Coffee shop", image: "Coffee shop-35.jpg" },
      { name: "Loma Brewing", image: "Loma Brewing-40.jpg" },
      { name: "Loma Brewing", image: "Loma Brewing-41.jpg" },
      { name: "OnPoint", image: "OnPoint_200.jpg" },
      { name: "OnPoint", image: "OnPoint_226.jpg" },
      { name: "OnPoint", image: "OnPoint_771.jpg" },
      { name: "OnPoint", image: "OnPoint_775.jpg" },
      { name: "Bella Burgers", image: "SpotOn Bella Burgers-24.jpg" },
      { name: "Bella Burgers", image: "SpotOn Bella Burgers-45.jpg" },
      { name: "Bella Burgers", image: "SpotOn Bella Burgers-97.jpg" },
      { name: "Bella Burgers", image: "SpotOn Bella Burgers-117.jpg" },
      { name: "Auto Body Shop", image: "SpotOn_Auto Body Shop-8.jpg" },
      { name: "Auto Body Shop", image: "SpotOn_Auto Body Shop-13.jpg" },
      { name: "Eye Glass Store", image: "SpotOn_Eye Glass Store-10.jpg" },
      { name: "Food Truck", image: "SpotOn_Food Truck_Photos-21.jpg" },
    ],
  },
  {
    title: "Product Renderings",
    images: [
      { name: "PAX-Aries8", image: "PAX-Aries8_C_v02.png" },
      { name: "PAX-A920", image: "PAX-A920.png" },
      { name: "SpotOn Monitor", image: "SpotOn_Monitor_C_v02.png" },
      { name: "SpotOn_Monitor", image: "SpotOn_Monitor_B_v02.png" },
      { name: "SpotOn_Monitor", image: "SpotOn_Monitor_A_v02.png" },
      { name: "Restaurant Solution", image: "RestaurantSolution_C_v04.png" },
      { name: "Restaurant Solution", image: "RestaurantSolution_B_v04.png" },
      { name: "Restaurant Solution", image: "RestaurantSolution_A_v04.png" },
    ],
  },
];

const DownloadSection = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [download, setDownload] = useState("");
  const showModal = (data) => {
    switch (data) {
      case "headshots":
        setModalData(headshots);
        setDownload("executive-headshots.zip");
        setIsModalVisible(true);
        return;
      case "products":
        setModalData(products);
        setDownload("product-shots.zip");
        setIsModalVisible(true);
        return;
      default:
        setModalData([]);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <section className="download-section special-margin">
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="rounded-md"
      >
        <div className="w-full p-2">
          <a
            className="absolute top-[8px] left-8 md:left-[75%] xl:left-[85%] md:top-[5%] bg-primary rounded-full border-none py-2 px-4 md:py-4 md:px-6 font-bold text-white hover:text-white hover:bg-[#6F95F9] transition-all duration-200"
            href={`/${download}`}
            download={download}
          >
            Download All
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "0px 5px 2px 10px" }}
            />
          </a>

          {modalData.map((data) => (
            <div>
              <HeadingBuilder
                className="pl-6 mt-12 mb-2"
                level={2}
                injectionType={2}
              >
                {data.title}
              </HeadingBuilder>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full p-2">
                {data.images.map((image) => (
                  <div className="w-full p-4 ">
                    <figure className=" rounded-md ">
                      <div className="relative w-full h-[calc(100%-28px)]">
                        <span className="rounded-md z-10 absolute left-0 top-0 bg-[rgba(0,0,0,.3)] w-full h-full" />
                        <Image
                          imageName={image.image}
                          className=" rounded-md"
                        />
                        <p className="absolute left-4 bottom-4 font-bold text-[24px] z-20 text-white">
                          {image.name}
                        </p>
                        <a
                          className="absolute z-20 rotate-90 left-[50%] top-[97%] bg-primary rounded-full border-none w-[32px] h-[32px] font-bold text-white hover:text-white hover:bg-[#6F95F9] transition-all duration-200"
                          href={`/${image.image}`}
                          download={image.image}
                        >
                          <img
                            src={chevron}
                            alt="chevron icon"
                            className="translate-x-[12px] translate-y-[6px]"
                          />
                        </a>
                      </div>
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <Row align="middle">
        <div className="download-white" />
        <div className="download-blue hidden lg:block" />
        <Col lg={12} sm={24}>
          <h2 className="download-title">
            Media <br />
            resources<span className="blue_dot">.</span>
          </h2>
          <p className="download-p pb-3">
            Download a .zip archive of all resources.
          </p>
          <a
            className="bg-primary rounded-full border-none py-4 px-6 font-bold text-white hover:text-white hover:bg-[#6F95F9] transition-all duration-200"
            href="/spoton-media-assets.7z"
            download="spoton-media-assets.7z"
          >
            Download
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "0px 5px 2px 10px" }}
            />
          </a>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24}>
          <Row
            gutter={[32, 32]}
            className="download-card-cont no-gutter"
            justify="center"
            align="middle"
          >
            <Col lg={12} md={12} sm={24} className="pt-16">
              <div className="download-card ">
                <img src={logo} alt="" />
                <h4 className="download-card-title mt-[75px]">
                  Brand <br /> assets<span className="text-primary">.</span>
                </h4>
                <CtaSecondary
                  ctaTitle="Download"
                  download="logos.zip"
                  target="/SpotOn-logos.zip"
                />
                <div className="download-card-shadow" />
              </div>
              <div className="download-card special-blue">
                <img src={logo} alt="" />
                <h4 className="download-card-title mt-[100px]">
                  Videos<span className="text-primary">.</span>
                </h4>
                <CtaSecondary
                  isSliderAnchor
                  ctaTitle="View"
                  target="/media/#videos"
                />
                <div className="download-card-shadow" />
              </div>
            </Col>
            <Col lg={12} md={12} sm={24}>
              <div className="download-card periwinkle ">
                <img src={logoCir} alt="" />
                <h4 className="download-card-title mt-[100px]">
                  Photography<span className="text-primary">.</span>
                </h4>
                <CtaSecondary ctaTitle="View" target="/media/photography" />
                <div className="download-card-shadow" />
              </div>
              <div className="download-card skyblue ">
                <img src={logoCir} alt="" />
                <h4 className="download-card-title mt-[75px]">
                  Executive <br /> headshots
                  <span className="text-primary">.</span>
                </h4>
                <CtaSecondary ctaTitle="View" target="/media/executive-bios" />
                <div className="download-card-shadow " />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default DownloadSection;
