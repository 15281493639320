import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import Carousel from "nuka-carousel";

import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import useWindowSize from "../../hooks/use-window-size";
import VideoSlide from "./VideoSlide";
import ArrowRight from "../CarouselCustomControls/ArrowRight";
import ArrLeft from "./ArrLeft";
import ArrRight from "./ArrRight";
import ArrowLeft from "../CarouselCustomControls/ArrowLeft";

const VideoSlider = ({ videoElements, customArr }) => {
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const [realIndex, updateIndex] = useState(0);
  const size = useWindowSize();
  const [ref, inView] = useInView({
    threshold: 0.1,
  });
  const goBackOne = () => {
    if (realIndex === 0) {
      updateIndex(videoElements.length - 1);
    } else {
      updateIndex(realIndex - 1);
    }
  };
  const goForwardOne = () => {
    if (realIndex === videoElements.length) {
      updateIndex(0);
    } else {
      updateIndex(realIndex + 1);
    }
  };
  const controls = useAnimation();

  useEffect(() => {
    if (inView && !isMobileOnly) {
      controls.start("visible");
    }
  }, [inView]);

  useEffect(() => {
    const { width } = size;
    const isMobile = width <= 576;
    const isTablet = width > 576 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200 && width <= 1700;
    const screenXXL = width > 1700;

    const slides = () => {
      if (isMobile) return 1;
      if (isTablet) return 1.5;
      if (isSreenLG) return 2;
      if (screenXL) return 1.9;
      if (screenXXL) return 1.9;
      return 2;
    };
    const spacing = () => {
      if (isMobile) return 32;
      if (isTablet) return 32;
      if (screenXXL) return 32;
      return 32;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);

  return (
    <section className="video-media relative rounded-[32px] mb-[70px]">
      <h2
        className="new-media-title new-video-title ml-[16px] md:ml-[32px] xl:ml-[150px]"
        style={{ textAlign: "left", color: "#fff" }}
      >
        Videos
        <span className="blue_dot">.</span>
      </h2>
      <div className="absolute top-[7.5%] md:top-[15%] right-[5%]">
        <ArrLeft
          onClick={goBackOne}
          className="translate-x-[12px] md:translate-x-0 special-cursor"
        />
        <ArrRight
          onClick={goForwardOne}
          className="rotate-180 translate-y-[-15px] translate-x-[-12px] md:translate-x-0 special-cursor"
        />
      </div>
      <Row className="video-media-cont">
        <Col lg={24} md={24} sm={24} xs={24}>
          <Carousel
            slidesToShow={numberOfSlides}
            cellSpacing={cellSpacing}
            enableKeyboardControls
            dragging
            slideIndex={customArr ? realIndex : 2}
            wrapAround
            cellAlign="center"
            initialSlideHeight={420}
            className="products__carousel"
            renderCenterLeftControls={({ previousSlide }) =>
              customArr ? null : <ArrowLeft onClick={previousSlide} />
            }
            renderCenterRightControls={({ nextSlide }) =>
              customArr ? null : <ArrowRight onClick={nextSlide} />
            }
            renderBottomCenterControls={() => null}
          >
            {videoElements.map((vid) => (
              <VideoSlide
                videoId={vid.videoId}
                posterImg={vid.posterImg}
                title={vid.title}
                key={vid.videoId}
              />
            ))}
          </Carousel>
        </Col>
      </Row>
    </section>
  );
};

VideoSlider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  videoElements: PropTypes.array,
};

VideoSlider.defaultProps = {
  videoElements: [],
};

export default VideoSlider;
